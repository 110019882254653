(function () {
    'use strict';

    angular.module("aerosApp")
        .factory('EquipmentAndOperatorModal', ["$uibModal", function ($uibModal) {
            function open(equipmentAndOperatorFields, equipmentAndOperatorModel, saveAsDefault) {
                var modal = $uibModal.open({
                    size: "md",
                    templateUrl: '/static/templates/project/setEquipmentAndOperatorModal.html',
                    resolve: {
                        equipmentAndOperatorFields: function () {
                            return equipmentAndOperatorFields;
                        },
                        equipmentAndOperatorModel: function () {
                            return equipmentAndOperatorModel;
                        },
                        saveAsDefault: function(){
                            return saveAsDefault;
                        }
                    },
                    controller: 'EquipmentAndOperatorModalController as vm'
                });

                return modal.result;
            }

            return {
                open: open
            }
        }])
        .controller('EquipmentAndOperatorModalController', EquipmentAndOperatorModalController);

    EquipmentAndOperatorModalController.$inject = ['$uibModalInstance', 'equipmentAndOperatorFields',
        'equipmentAndOperatorModel', 'saveAsDefault'];

    function EquipmentAndOperatorModalController($uibModalInstance, equipmentAndOperatorFields, equipmentAndOperatorModel, saveAsDefault) {
        var vm = this;
        vm.saveAsDefault = saveAsDefault;
        console.log(saveAsDefault);
        vm.equipmentAndOperatorFields = equipmentAndOperatorFields;
        vm.equipmentAndOperatorModel = equipmentAndOperatorModel ?
            equipmentAndOperatorModel : {};

        vm.add = function add() {
            $uibModalInstance.close({
                equipmentAndOperatorFields: vm.equipmentAndOperatorFields,
                equipmentAndOperatorModel: vm.equipmentAndOperatorModel,
                saveAsDefault: vm.saveAsDefault
            });
        }
    }
})();
